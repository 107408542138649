import {memo, useRef, useState} from 'react'
import Button from '../Auth/Button'
import { Search } from '../Icons'
import styles from './_searchbar.module.sass'
import { useRouter } from 'next/router'

const SearchBar = memo(() => {
  const router = useRouter()

  const searchBar = useRef(null)

  let timeout = null

  const [reset, setReset] = useState({})

  const searchWord = () => {
    const searchQuery = searchBar.current.value.toLowerCase().trim()
    if(searchQuery !== ''){
        router.push(`/app/search/${searchQuery}`)
        clearTimeout(timeout)
        setTimeout(() => {
          searchBar.current.value = ''
          searchBar.current.blur()
        }, 100)
    }else{
      setReset({reset: true})
    }
  }

  const onKeyDown = (e) => {
      if(e.key.toLowerCase() === 'enter'){
          e.preventDefault()
          searchWord()
      }
  }

  return (
    <div className={styles.search}>
      <input ref={searchBar} type='search' placeholder='Search for any word' onKeyDown={onKeyDown} />
      <Button reset={reset} svg={<Search />} onClick={searchWord} colors={['var(--primary)', 'var(--primary)']} reference={searchBar} />
    </div>
  )

})

export default SearchBar