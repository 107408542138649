import styles from './_header.module.sass'
import Link from 'next/link'
import Image from 'next/image'
import logo from '../../public/images/icon.webp'
import { memo, useEffect } from 'react'
import { useRouter } from 'next/router'
import { useRecoilState } from 'recoil'
import showBackBtnAtom from '../../atoms/showBackBtnAtom'

const Header = () => {

    const BackBtn = memo(() => {

        const router = useRouter()

        let routeTimeout = null
    
        const routeBack = () => {
            clearTimeout(routeTimeout)
            routeTimeout = setTimeout(()=>{
                if(window.navigation.canGoBack){
                    router.back()
                }else{
                    router.push('/app')
                }
            }, 300)
        }

        const [showBackBtn, setShowBackBtn] = useRecoilState(showBackBtnAtom)
        
        useEffect(()=>{
            if(window&&window.navigation&&window.navigation.canGoBack){
                setShowBackBtn(true)
            }else{
                setShowBackBtn(false)
            }
        }, [window.navigation])

        const ArrowLeft = () => <svg viewBox="0 0 40 40"><path d="M33.125 19.125H8.98L20.841 7.5a.875.875 0 1 0-1.224-1.249L6.262 19.339a.878.878 0 0 0-.262.626V20c0 .319.171.598.427.751L19.69 33.75a.872.872 0 0 0 1.238-.012.876.876 0 0 0-.013-1.238L9.055 20.875h24.07a.876.876 0 0 0 0-1.75z"/></svg>   
        
        if(showBackBtn){
            return (
                <div className={styles.backBtn} onMouseDown={routeBack}>
                    <ArrowLeft />
                </div>
            )
        }else{
            return null
        }

    })

    return (
        <div className={styles.header}>
            <BackBtn />
            <Link href='/app'>
                <div className={styles.logo}><Image src={logo} alt='Wordbank' sizes='44px' fill priority /></div>
                <span>Wordbank</span>
            </Link>
        </div>
    )

}

export default memo(Header)