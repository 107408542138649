import { memo } from 'react'
import styles from './_bottomnavigation.module.sass'
import Link from 'next/link'
import { useRouter } from 'next/router'

const Icons = {
    settings: () => {
        return <svg><circle cx="18" cy="9" r="2" transform="rotate(90 18 9)"/><path d="M18 4v3m0 4v9"/><circle cx="6" cy="9" r="2" transform="rotate(90 6 9)"/><path d="M6 4v3m0 4v9"/><circle cx="12" cy="15" r="2" transform="rotate(90 12 15)"/><path d="M12 4v9m0 4v3"/></svg>
    },
    search: () => {
        return <svg><path d="M14.412 14.412 20 20"/><circle cx="10" cy="10" r="6"/></svg>
    },
    bookmark: () => {
        return <svg><path d="M17 6.659v12.927l-5-3.178-5 3.178V6.659C7 5.19 8.12 4 9.5 4h5C15.88 4 17 5.19 17 6.659Z"/></svg>
    }
}


const BottomNavigation = () => {
    const router = useRouter()
    return (
        <div className={styles.bar} id='bottomNav'>
            <Link prefetch={false} className={`${styles.btn} ${router.pathname==='/app/settings'?styles.activeLink:null}`} href="/app/settings" aria-label='settings'><Icons.settings /></Link>
            <Link prefetch={false} className={`${styles.btn} ${router.pathname==='/app'?styles.activeLink:null}`} href='/app' aria-label='explore'><Icons.search /></Link>
            <Link prefetch={false} className={`${styles.btn} ${router.pathname==='/app/library'?styles.activeLink:null}`} href="/app/library" aria-label='library'><Icons.bookmark /></Link>
        </div>
    )
  
}


export default memo(BottomNavigation)