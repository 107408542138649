import {Fragment, memo, useEffect} from 'react'
import './index.sass'
import Head from "next/head"
import { RecoilRoot, useRecoilState, useSetRecoilState } from 'recoil'
import Header from '../components/Header'
import BottomNavigation from '../components/BottomNavigation'
import displayAtom from '../atoms/displayAtom'
import { onAuthStateChanged } from 'firebase/auth'
import userIdAtom from '../atoms/userIdAtom'
import {auth, db, db_ultimate} from '../firebase'

import {collection, doc, getDoc, getDocs, orderBy, query} from 'firebase/firestore'
import userDataAtom from '../atoms/userDataAtom'
import bookmarksAtom from '../atoms/bookmarksAtom'
import { useRouter } from 'next/router'
import isAppAtom from '../atoms/isAppAtom'
import modalAtom from '../atoms/modalAtom'
import Modal from '../components/Modal'
import SearchBar from '../components/SearchBar'
import ultimateAtom from '../atoms/ultimateAtom'
import { stripeSecretKey } from '../variables'
import Stripe from 'stripe'

const UltimateEffect = memo(() => {
  const setUltimate = useSetRecoilState(ultimateAtom)
  const stripe = new Stripe(stripeSecretKey)
  const [userId] = useRecoilState(userIdAtom)

  const checkTrialStatus = async () => {
    
    const checkFirebase = async () => {
      
      const userStatus = (await getDoc(doc(db_ultimate, 'customers', userId))).data()
      
      if(userStatus){
        const startDate = userStatus.startDate
        if(startDate){
          const currentDate = new Date().getTime()
          const endDate = new Date(startDate.toDate()).getTime() + (7 * 24 * 60 * 60 * 1000)
          if(currentDate >= endDate){
            setUltimate(false)
          }else{
            setUltimate(true)
          }
        }else{
          setUltimate(false)
        }
      }else{
        setUltimate(false)
      }

    }

    stripe.customers.list({email: userId, limit: 1, expand: ["data.subscriptions"]}).then(data=>{
      if(data?.data[0]?.subscriptions?.data && data.data[0].subscriptions.data.length > 0){
        setUltimate('paid')
      }else{
        checkFirebase()
      }
    }).catch(()=>{
      checkFirebase()
    })

  }

  useEffect(()=>{
    if(userId){
      checkTrialStatus()
    }
  }, [userId])
  return null
})

const SetUserDataEffect = memo(() => {

  const [userId] = useRecoilState(userIdAtom)

  const [userData, setUserData] = useRecoilState(userDataAtom)

  useEffect(()=>{
    if(!userId) return
    if(!userData && userData !== false && userId && db){
      const docRef = doc(db, 'users', userId)
      getDoc(docRef).then(docSnap=>{
        if(docSnap.exists()){
          setUserData(docSnap.data())
        }else{
          setUserData(false)
        }
      })
    }
  }, [userId])

  return null

})

const Effects = memo(() => {

  const [display, setDisplay] = useRecoilState(displayAtom)

  useEffect(()=>{

      if(display.width === 0 && window.innerWidth > 0 && display.height === 0 && window.innerHeight > 0){
        setDisplay({height: window.innerHeight-180, width: window.innerWidth})
      }

      let resizeTimeout = null
      let resizeDisplay = {width: window.innerWidth, height: window.innerHeight-180}

      window.onresize = () => {

          clearTimeout(resizeTimeout)
          
          const windowHeight = window.innerHeight - 180

          resizeTimeout = setTimeout(()=>{

            const resizeEmptyScreen = () => {
              if(document.getElementById('emptyScreen') && document.getElementById('emptyScreen').style.height !== windowHeight+'px'){
                document.getElementById('emptyScreen').style.height = windowHeight+'px'
              }
            }
            resizeEmptyScreen()

            const setNewDisplay = () => {
              if(display.width !== window.innerWidth){
                resizeDisplay.width = window.innerWidth
              }
              if(display.height !== window.innerHeight-180){
                resizeDisplay.height = window.innerHeight-180
              }
            }
            setNewDisplay()

            setDisplay(resizeDisplay)

          }, 300)
      }

  }, [display])

  const [userId, setUserId] = useRecoilState(userIdAtom)
  const setBookmarks = useSetRecoilState(bookmarksAtom)

  useEffect(()=>{
    if(!userId){
      if(auth&&auth.currentUser&&auth.currentUser.email){
        setUserId(auth.currentUser.email)
      }else{
        onAuthStateChanged(auth, async (user) => {
          if(user&&user.email){
            setUserId(user.email)
          }else{
            setUserId(false)
            setBookmarks(false)
          }
        })
      }
    }
  }, [])

  return null

})

const IsAppEffect = memo(()=>{
  const router = useRouter()
  const [isApp, setIsApp] = useRecoilState(isAppAtom)
  useEffect(() => {
    if(router.pathname&&router.pathname.split('/')&&router.pathname.split('/')[1]){
      if(router.pathname.split('/')[1] === 'app'){
        if(!isApp){
          setIsApp(true)
        }
      }else if(isApp){
        setIsApp(false)
      }
    }
  }, [router])
  return null
})

const AppContent = memo(({Component, pageProps}) => {
  const [isApp] = useRecoilState(isAppAtom)
  const [modal] = useRecoilState(modalAtom)
  return (
    <Fragment>
      {modal?<Modal {...modal} />:null}
      {isApp?
        <div className='container'>
          <Header />
          <Component {...pageProps} />
          <SearchBar />
          <BottomNavigation />
        </div>
      :
      <div className='container'><Component {...pageProps} /></div>
      }
    </Fragment>
  )
})

const App = memo(({Component, pageProps}) => {
  return (
    <Fragment>
      <Head>
          <meta httpEquiv="Content-Type" content="text/html; charset=utf-8" />
          <meta name="viewport" content="width=device-width, initial-scale=1" />
          <meta name="theme-color" content="#000000" />
          <meta name="author" content="Anmol Virk" />
          <title>Wordbank - Vocabulary Improvement Tool</title>
          <meta name="description" content="Vocabulary Improvement Tool" />
          <link rel="canonical" href="https://wordbank.vercel.app/" />

          <meta name="application-name" content="Wordbank" />
          <meta name="apple-mobile-web-app-capable" content="yes" />
          <meta name="apple-mobile-web-app-status-bar-style" content="default" />
          <meta name="apple-mobile-web-app-title" content="Wordbank" />
          <meta name="description" content="Vocabulary Improvement Tool" />
          <meta name="format-detection" content="telephone=no" />
          <meta name="mobile-web-app-capable" content="yes" />
          <meta name="msapplication-config" content="/browserconfig.xml" />
          <meta name="msapplication-TileColor" content="#000000" />
          <meta name="msapplication-tap-highlight" content="no" />
          <meta name="theme-color" content="#000000" />

          <link rel="apple-touch-startup-image" href="/images/icon.webp" />

          <link rel="apple-touch-icon" href="/images/logo.png" />
          <link rel="apple-touch-icon" sizes="152x152" href="/images/logo.png" />
          <link rel="apple-touch-icon" sizes="180x180" href="/images/logo.png" />
          <link rel="apple-touch-icon" sizes="167x167" href="/images/logo.png" />
          
          <link rel="manifest" href="/manifest.json" />
          <link rel="mask-icon" href="/images/icon.webp" color="#000000" />

          <meta name="twitter:card" content="summary" />
          <meta name="twitter:url" content="https://wordbank.vercel.app/" />
          <meta name="twitter:title" content="Wordbank" />
          <meta name="twitter:description" content="Vocabulary Improvement Tool" />
          <meta name="twitter:image" content="https://wordbank.vercel.app/images/preview.webp" />
          <meta name="twitter:creator" content="@AnmolVirk" />

          <meta property="og:type" content="website" />
          <meta property="og:title" content="Wordbank" />
          <meta property="og:description" content="Vocabulary Improvement Tool" />
          <meta property="og:site_name" content="Wordbank" />
          <meta property="og:url" content="https://wordbank.vercel.app/" />
          <meta property="og:image" content="https://wordbank.vercel.app/images/preview.webp" />
      </Head>
      <RecoilRoot>
        <AppContent Component={Component} pageProps={pageProps} />
        <Effects />
        <SetUserDataEffect />
        <IsAppEffect />
        <UltimateEffect />
      </RecoilRoot>
    </Fragment>
  )
})

export default App