import { memo } from "react"
import { useSetRecoilState } from "recoil"
import modalAtom from "../../atoms/modalAtom"
import Button from "../Auth/Button"
import styles from './_modal.module.sass'
import {useDetectClickOutside} from 'react-detect-click-outside'

const Modal = memo(({icon, message, actions}) => {
  const setModal = useSetRecoilState(modalAtom)
  const ref = useDetectClickOutside({onTriggered: ()=>setModal(false)})
  return (
    <div className={styles.modal}>
      <div className={styles.content} ref={ref}>
        {icon?icon:null}
        <div className={styles.message}>{message}</div>
        <div className={styles.actions}>
          {actions?actions.map((item, i)=>{
            return <Button key={i} text={item.text?item.text:null} svg={item.svg?item.svg:null} onClick={item.onClick?item.onClick:null} colors={item.colors?item.colors:['var(--primary)', 'var(--brand3)', 'var(--border)']} />
          }):null}
        </div>
      </div>
    </div>
  )
})

export default Modal