import {initializeApp} from 'firebase/app'
import {getAuth, GoogleAuthProvider} from 'firebase/auth'
import {getFirestore} from 'firebase/firestore'

const firebaseConfig = {
  apiKey: "AIzaSyCE8WytlkST43BY3aYFYT7IsZFZqRBvGhU",
  authDomain: "wordbank-vocabulary.firebaseapp.com",
  projectId: "wordbank-vocabulary",
  storageBucket: "wordbank-vocabulary.appspot.com",
  messagingSenderId: "785977308094",
  appId: "1:785977308094:web:69f7f08fafabd790acffef",
  measurementId: "G-4C1WTKF29L"
}

const app = initializeApp(firebaseConfig)
const db = getFirestore(app)
const auth = getAuth(app)
const google = new GoogleAuthProvider()

const glossaryFirebaseConfig = {
  apiKey: "AIzaSyC93Gluo5yQzx-3x2Sfds78e0yOkpmToCI",
  authDomain: "glossary-wordbank.firebaseapp.com",
  projectId: "glossary-wordbank",
  storageBucket: "glossary-wordbank.appspot.com",
  messagingSenderId: "337334039713",
  appId: "1:337334039713:web:f33d5c8d2de31756d22ce0",
  measurementId: "G-2K0G3C458M"
}

const app_glossary = initializeApp(glossaryFirebaseConfig, 'glossary')
const db_glossary = getFirestore(app_glossary)

const feedbackFirebaseConfig = {
  apiKey: "AIzaSyAtO90LxyhBJsCI-mRQXJm2NPKzmkM8zaM",
  authDomain: "feedback-wordbank.firebaseapp.com",
  projectId: "feedback-wordbank",
  storageBucket: "feedback-wordbank.appspot.com",
  messagingSenderId: "965337098752",
  appId: "1:965337098752:web:a3c51c9733eab6be0ec6e5",
  measurementId: "G-RE3HEFSYHX"
}

const app_feedback = initializeApp(feedbackFirebaseConfig, 'feedback')
const db_feedback = getFirestore(app_feedback)

const notificationsFirebaseConfig = {
  apiKey: "AIzaSyA_A68u_IZfY0jTCCjC62TXKbDeiut0mLM",
  authDomain: "notifications-wordbank.firebaseapp.com",
  projectId: "notifications-wordbank",
  storageBucket: "notifications-wordbank.appspot.com",
  messagingSenderId: "834393577819",
  appId: "1:834393577819:web:da3c1ed0c9142a15aae27e",
  measurementId: "G-TJBXKH480E"
}

const app_notifications = initializeApp(notificationsFirebaseConfig, 'notifications')
const db_notifications = getFirestore(app_notifications)

const bookmarksFirebaseConfig = {
  apiKey: "AIzaSyCuL3NAIkqn8_ZYYbgPdN7nGJQdTRql_Vk",
  authDomain: "bookmarks-wordbank.firebaseapp.com",
  projectId: "bookmarks-wordbank",
  storageBucket: "bookmarks-wordbank.appspot.com",
  messagingSenderId: "661518877216",
  appId: "1:661518877216:web:d100897700b8f012297245",
  measurementId: "G-GBF18D1RJM"
}

const app_bookmarks = initializeApp(bookmarksFirebaseConfig, 'bookmarks')
const db_bookmarks = getFirestore(app_bookmarks)

const collectionsFirebaseConfig = {
  apiKey: "AIzaSyBjWs-5ml0hFT6646Wki2D0by0T4bKvSNw",
  authDomain: "collections-wordbank.firebaseapp.com",
  projectId: "collections-wordbank",
  storageBucket: "collections-wordbank.appspot.com",
  messagingSenderId: "611511718597",
  appId: "1:611511718597:web:2ec53c8ab89443d14696ac",
  measurementId: "G-32DN4G7REW"
}

const app_collections = initializeApp(collectionsFirebaseConfig, 'collections')
const db_collections = getFirestore(app_collections)

const ultimateFirebaseConfig = {
  apiKey: "AIzaSyARD38TYunTQXyfYK61BkN5OzLnkfl1gKU",
  authDomain: "ultimate-wordbank.firebaseapp.com",
  projectId: "ultimate-wordbank",
  storageBucket: "ultimate-wordbank.appspot.com",
  messagingSenderId: "77507623695",
  appId: "1:77507623695:web:e396092748696293cb1c7a",
  measurementId: "G-K26HREYMXB"
}

const app_ultimate = initializeApp(ultimateFirebaseConfig, 'ultimate')
const db_ultimate = getFirestore(app_ultimate)

export {auth, db, google, db_glossary, db_feedback, db_notifications, db_bookmarks, db_collections, db_ultimate}