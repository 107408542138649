import Link from "next/link"
import { useRouter } from "next/router"
import { memo, useEffect, useRef, useState } from "react"
import styles from './_button.module.sass'

const Button = memo(({text, svg, colors, onClick, path, style, reset, svgClass, textStyle, reference, className, textClass}) => {

  const buttonRef = useRef(null)
  const spanRef = useRef(null)
  
  useEffect(()=>{
    if(buttonRef && spanRef){
      buttonRef.current.onmouseenter ??= (e) => {
        spanRef.current.style.left = e.offsetX+'px'
        spanRef.current.style.top = e.offsetY+'px'
      }
      buttonRef.current.onmouseleave ??= (e) => {
        spanRef.current.style.left = e.offsetX+'px'
        spanRef.current.style.top = e.offsetY+'px'
      }
    }
  }, [])

  const [showLoader, setShowLoader] = useState(false)

  const clickedOnce = useRef(false)

  const createRipple = (e) => {

    if(!showLoader){
      setShowLoader(true)
    }

    const button = e.currentTarget;
    const diameter = Math.max(button.clientWidth, button.clientHeight);
  
    const circle = document.createElement("div");
    circle.style.width = circle.style.height = `${diameter}px`;
    circle.style.left = `${e.clientX - button.offsetLeft - diameter/2}px`;
    circle.style.top = `${-diameter/2 + button.clientHeight/2}px`;
    circle.classList.add(styles.ripple);
    const ripple = button.getElementsByClassName(styles.ripple)[0];

    if (ripple) {
      ripple.remove();
    }
  
    button.appendChild(circle);

  }

  useEffect(()=>{
    if(typeof reset === 'object' && Object.keys(reset).length > 0 && showLoader){
      setShowLoader(false)
      clickedOnce.current = false
    }
  }, [reset])

  const onMouseUp = () => {
    if(!clickedOnce.current){
      onClick()
    }
    if(showLoader && !clickedOnce.current){
      clickedOnce.current = true
    }
    if(!showLoader){
      clickedOnce.current = false
    }
  }

  const router = useRouter()
  
  let timeout = null

  useEffect(() => {
    if(!path && reference?.current?.value){
      setShowLoader(true)
      clearTimeout(timeout)
      timeout = setTimeout(() => {
        setShowLoader(false)
      }, 1000)
    }
  }, [router])
  
  if(path){
    return (
      <Link href={path} prefetch={false} shallow={true} className={`${styles.btn} ${styles.link} ${className?className:''}`} ref={buttonRef} onMouseDown={(e)=>createRipple(e)} style={colors?style?{...style, backgroundColor: colors[0]}:{backgroundColor: colors[0]}:style}>
        {showLoader?
        <div className={styles.loader} />
        :
        <div className={`${styles.content} ${svgClass?styles[svgClass]:''}`}>
          {svg?svg:null}
          <div className={`${styles.text} ${textClass?textClass:''}`} style={textStyle?{...textStyle}:null}>{text}</div>
        </div>}
        <span ref={spanRef} style={colors&&colors[1]?{backgroundColor: colors[1]}:null} />
        <div className={`${styles.backgroundText} ${textClass?textClass:''}`} style={{color: 'transparent'}}>{text}</div>
      </Link>
    )
  }else{
    return (
      <button type="button" className={className?`${styles.btn} ${className}`:styles.btn} ref={buttonRef} onMouseDown={(e)=>createRipple(e)} onMouseUp={onMouseUp} style={colors?style?{...style, backgroundColor: colors[0]}:{backgroundColor: colors[0]}:style}>
        <span ref={spanRef} style={colors&&colors[1]?{backgroundColor: colors[1]}:null} />
        {showLoader?
        <div className={styles.loader} />
        :
        <div className={`${styles.content} ${svgClass?styles[svgClass]:''}`}>
          {svg?svg:null}
          <div className={`${styles.text} ${textClass?textClass:''}`}>{text}</div>
        </div>}
        <div className={`${styles.backgroundText} ${textClass?textClass:''}`} style={{color: 'transparent'}}>{text}</div>
      </button>
    )
  }
})

export default Button